<!--
 * @Description: 企业认证选择认证方式页面
 * @Author: huacong
 * @Date: 2021-06-22 10:07:23
 * @LastEditTime: 2021-07-28 13:39:05
 * @LastEditors: huacong
-->
<template>
  <!-- 选择发布类型 -->
  <div class="contentBox">
    <section v-if="haveDataBtn">
      <p class="text-center text-title">企业关联认证</p>
      <div class="btnBox">
        <div class="imgText clearfix" @click="goPostOne">
          <div>
            <img :src="iconleft" alt="" />
          </div>
          <div class="text">
            <span>已有企业</span><br />
            <span>公司信息已经存在，只需要选择已有公司信息进行认证</span>
          </div>
        </div>
        <div class="imgText clearfix" @click="goPostTwo">
          <div>
            <img :src="iconright" alt="" />
          </div>
          <div class="text">
            <span>新建企业</span><br />
            <span>公司信息首次在平台上认证，需要完善企业信息并认证</span>
          </div>
        </div>
      </div>
    </section>
    <section v-if="!haveDataBtn">
      <p class="text-center realTitle">您已完成企业认证,企业基本信息如下:</p>
      <div class="rowbox">
        <el-row class="infoBox">
          <el-col :span="3">logo</el-col>
          <el-col :span="9"
            ><img class="logoimg" :src="imageUrl" alt=""
          /></el-col>
          <el-col :span="3">公司名称</el-col>
          <el-col :span="9">{{ comInfo.com_name }}</el-col>
        </el-row>
        <el-row class="infoBox">
          <el-col :span="3">对外名称</el-col>
          <el-col :span="9">{{ comInfo.com_external_name }}</el-col>
          <el-col :span="3">公司性质</el-col>
          <el-col :span="9">{{ comInfo.com_nature }}</el-col>
        </el-row>
        <el-row class="infoBox">
          <el-col :span="3">公司规模</el-col>
          <el-col :span="9">{{ comInfo.com_size }}</el-col>
          <el-col :span="3">公司网站</el-col>
          <el-col :span="9">{{ comInfo.com_website }}</el-col>
        </el-row>
        <el-row class="infoBox">
          <el-col :span="3">固定电话</el-col>
          <el-col :span="9">{{ comInfo.com_fixed }}</el-col>
          <el-col :span="3">公司地址</el-col>
          <el-col :span="9"
            >{{ comInfo.com_province }}{{ comInfo.com_city
            }}{{ comInfo.com_address }}</el-col
          >
        </el-row>
        <el-row class="infoBox">
          <el-col :span="3">代办范围</el-col>
          <el-col :span="9">{{ comInfo.com_business }}</el-col>
          <el-col :span="3">业务范围</el-col>
          <el-col :span="9">{{ comInfo.com_commission }}</el-col>
        </el-row>
        <el-row class="infoBox">
          <el-col :span="3">公司介绍</el-col>
          <el-col :span="21">{{ comInfo.com_synopsis }}</el-col>
        </el-row>
      </div>
      <div class="text-center" style="margin-top:20px">
        <el-button type="primary" @click="unbundling">解绑企业</el-button>
      </div>
    </section>
    <el-dialog
      title="确认解绑"
      :close-on-click-modal="false"
      :visible.sync="unbundlingShow"
    >
      <div>解绑公司后，需要重新登录账号，是否确认解绑！</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="unbundlingShow = false">取消</el-button>
        <el-button type="primary" v-prevent-repeat-click @click="unbundlingBtn"
          >确定解绑</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      iconleft: require("@/assets/img/comreal01.png"),
      iconright: require("@/assets/img/comreal02.png"),
      haveDataBtn: true,
      comInfo: [],
      imageUrl: "",
      unbundlingShow: false,
    };
  },
  computed: {
    ...mapState(["comId"]),
    userInfo() {
      let k = localStorage.getItem("userInfo");
      if (k == null) {
        return {};
      }
      return JSON.parse(localStorage.getItem("userInfo"));
    },
  },
  methods: {
    goPostOne() {
      this.$router.push("afterhaveComReal");
    },
    goPostTwo() {
      this.$router.push("aftercompleteInfo");
    },
    unbundling() {
      this.unbundlingShow = true;
    },
    unbundlingBtn() {
      this.$http
        .post("company/index/unbound", { com_id: this.comInfo.id })
        .then((res) => {
          if (res.data.resultCode == 1) {
            this.unbundlingShow = false;
            this.$message.success("解绑成功，现在跳转登录页面！");
            setTimeout(() => {
              localStorage.removeItem("Authorization");
              localStorage.removeItem("userInfo");
              this.$router.push({ path: "/Login" });
            }, 1300);
          }
        });
    },
    // 获取企业已完善的信息
    getComInfo() {
      if (this.comId == 0) {
        this.$http.post("company/index/getcompanyinfo").then((res) => {
          if (res.data.resultCode === 1) {
            if (res.data.resultData.length < 1) {
              this.haveDataBtn = true;
              return;
            }
            this.haveDataBtn = false;
            this.comInfo = res.data.resultData;
            let data = res.data.resultData;
            // this.comInfo.url = data.com_license_url;
            if (data.com_file_url != "") {
              this.imageUrl = data.com_file_url;
            }
            // if (data.com_license_url != "") {
            //   this.licenseUrl = data.com_license_url;
            // }
          }
        });
      } else {
        this.$http
          .post("company/index/getcompanyinfo", { u_company: this.comId })
          .then((res) => {
            if (res.data.resultCode === 1) {
              if (res.data.resultData.length < 1) {
                this.haveDataBtn = true;
                return;
              }
              this.haveDataBtn = false;
              this.comInfo = res.data.resultData;
              let data = res.data.resultData;
              // this.comInfo.url = data.com_license_url;
              if (data.com_file_url != "") {
                this.imageUrl = data.com_file_url;
              }
              // if (data.com_license_url != "") {
              //   this.licenseUrl = data.com_license_url;
              // }
            }
          });
      }
    },
  },
  mounted() {
    this.getComInfo();
  },
};
</script>
<style scoped>
.text-title {
  font-size: 28px;
  font-weight: bold;
  color: #2d59df;
  padding-top: 30px;
}
.btnBox {
  padding: 40px 100px 100px;
  text-align: center;
}
.btnBox .imgText:first-child {
  margin-right: 36px;
}
.imgText {
  width: 320px;
  padding: 20px 20px 15px;
  border-radius: 8px;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  display: inline-block;
}
.imgText:hover {
  background: #eef9fd;
  cursor: pointer;
}
.imgText:hover .text span:first-child {
  color: #2d59df;
}
.imgText div {
  float: left;
}
.imgText > div:first-child {
  width: 55px;
}
.imgText .text {
  width: 180px;
  padding-left: 80px;
  padding-top: 0px;
  line-height: 50px;
}
.padding-20 {
  padding: 20px;
}
.imgText img {
  width: 120px;
}
.imgText .text span:first-child {
  font-size: 26px;
  font-weight: bold;
  color: #333333;
  line-height: 24px;
  display: inline-block;
  padding: 9px;
  padding-top: 0;
}
.imgText .text span:last-child {
  font-size: 18px;
  color: #999999;
  line-height: 24px;
  display: inline-block;
  padding-left: 9px;
}
.realTitle {
  font-size: 28px;
  font-weight: bold;
  color: #2573f1;
  line-height: 37px;
}
.rowbox {
  padding-top: 30px;
}
.infoBox {
  width: 800px;
  margin: 0 auto;
  border-bottom: 1px solid #eeeeee;
}
.rowbox .infoBox:first-child {
  border-top: 1px solid #eeeeee;
}
.rowbox .infoBox:first-child div:nth-child(2) {
  padding: 0;
}
.logoimg {
  height: 30px;
  padding-top: 8px;
  padding-left: 20px;
}
.rowbox .infoBox:last-child,
.rowbox .infoBox:last-child div {
  height: 100px;
}
.infoBox > div:first-child {
  border-left: 1px solid #eeeeee;
}
.infoBox > div {
  padding: 13px 20px;
}
.infoBox div:nth-child(odd) {
  text-align: center;
}
.infoBox > div {
  border-right: 1px solid #eeeeee;
}
</style>
